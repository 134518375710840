import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { PageLoader } from '../components/spinner';
import { withAuth } from '../components/with-auth';
import useUser from '../hooks/use-user';

function Main() {
  const { user } = useUser({ redirectTo: '/auth/login' });
  const router = useRouter();

  useEffect(() => {
    if (user && user.roles) {
      if (user.roles.includes('superuser')) {
        router.push('/organisations');
      } else {
        router.push('/knowledge-base');
      }
    }
  }, [user, router]);

  return <PageLoader />;
}

export default withAuth(Main, []);
