import _ from 'lodash';

/**
 * Check for required scopes
 *
 * Example:
 * ```js
 * const scopes = ['user:write'];
 * const userWriteOrAdmin = [['user:write'], ['admin']]
 * const userWriteAndAdmin = ['user:write', 'admin']
 * const singlePermission = 'user:write'
 * const noPermission = ['permission:not:in:scope']
 *
 * check(scopes, userWriteOrAdmin) // => true
 * check(scopes, userWriteAndAdmin) // => true
 * check(scopes, singlePermission) // => true
 * check(scopes, noPermission) // => false
 * ```
 *
 * @param {*} scopes
 * @param {*} requiredScopes
 * @returns
 */
export function check(scopes, requiredScopes) {
  if (!requiredScopes || !scopes) return false;
  const required = _.isString(requiredScopes)
    ? [[requiredScopes]]
    : _.isArray(requiredScopes) && requiredScopes.every(_.isString)
    ? [requiredScopes]
    : requiredScopes;

  // Check if scopes has sufficient permissions
  const sufficient = required.some((scope) => {
    return scope.every((permission) => {
      return scopes.indexOf(permission) !== -1;
    });
  });

  return sufficient;
}
