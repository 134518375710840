import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import useUser from '../hooks/use-user';
import { check } from '../lib/auth/guard';
import { PageLoader } from './spinner';

export function AuthenticatedPage({ children }) {
  const { user } = useUser({ redirectTo: '/auth/login' });

  if (!user) {
    return <PageLoader />;
  }

  return children;
}

const getDisplayName = (Component) =>
  Component.displayName || Component.name || 'Component';

export const withAuth = (
  Component,
  permissions = [],
  { redirectTo = '/403' } = {}
) =>
  Object.assign(
    ({ ...props }) => {
      const { user } = useUser({ redirectTo: '/auth/login' });
      const [isAllowed, setAllowed] = useState(
        !permissions || !permissions.length ? true : false
      );
      const router = useRouter();

      /**
       * Check for permissions and redirect if unsufficient
       */
      useEffect(() => {
        if (user && permissions.length) {
          const hasPermissions = check(user.scopes, permissions);
          setAllowed(hasPermissions);
          if (!hasPermissions) {
            router.push(redirectTo);
            toast.error('Geen permissies voor deze pagina');
          }
        }
      }, [user, isAllowed, router]);

      if (!user) {
        return <PageLoader />;
      }

      if (isAllowed) {
        return <Component {...props} />;
      }

      return null;
    },
    { displayName: `withAuth(${getDisplayName(Component)})` }
  );

export default withAuth;
